const cards = [
   {
      id: "img01",
      target: "",
      alt: "Chaise Exclusiva em Corda",
      title: "Chaise Exclusiva em Corda",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Chaise Búzios",
      title: "Chaise Búzios",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Chaise Stilo",
      title: "Chaise Stilo",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Big Chaise I",
      title: "Big Chaise I",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Chaise em Corda I",
      title: "Chaise em Corda I",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Chaise em Corda II",
      title: "Chaise em Corda II",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Chaise Dupla",
      title: "Chaise Dupla",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Big Chaise II",
      title: "Big Chaise II",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Chaise",
      title: "Chaise",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Chaise",
      title: "Chaise",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Chaise Dupla",
      title: "Chaise Dupla",
      description: "",
   },
]

export { cards }